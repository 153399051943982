import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { showToast } from "../../../Toaster/Toaster";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { AddCompanyOwnerMutation } from "../../../ReactQuery/Mutations/CompanyOwnerAddMutation";
import { CompanyOwner } from "../../../Types/UpdateCompanyInfoTypes";

export const AddCompanyOwner = () => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [companyOwners, setCompanyOwners] = useState<CompanyOwner[]>([
    {
      company_owner_name: "",
      company_owner_address: "",
      company_owner_designation: "",
      company_owner_nid: "",
      company_owner_phone: "",
      company_owner_email: "",
      company_representative: false,
      company_owner_photo: null,
      company_owner_nid_file: null,
      company_owner_biodata: null,
      company_owner_passport: null,
      company_owner_signature: null,
    },
  ]);

  const handleChangeInput = <K extends keyof CompanyOwner>(
    index: number,
    event: K,
    value: CompanyOwner[K]
  ) => {
    const values = [...companyOwners];
    values[index][event] = value;
    setCompanyOwners(values);
  };

  const handleAddFields = () => {
    setCompanyOwners([
      ...companyOwners,
      {
        company_owner_name: "",
        company_owner_address: "",
        company_owner_designation: "",
        company_owner_nid: "",
        company_owner_phone: "",
        company_owner_email: "",
        company_representative: false,
        company_owner_photo: null,
        company_owner_nid_file: null,
        company_owner_biodata: null,
        company_owner_passport: null,
        company_owner_signature: null,
      },
    ]);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...companyOwners];
    values.splice(index, 1);
    setCompanyOwners(values);
  };

  const formData = new FormData();

  const mutation = useMutation({
    mutationFn: (data: any) => AddCompanyOwnerMutation(data),
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_DETAILS],
      });
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const ownersWithoutFiles = companyOwners.map((owner: any) => {
      const {
        company_owner_photo,
        company_owner_nid_file,
        company_owner_biodata,
        company_owner_passport,
        company_owner_signature,
        ...otherFields
      } = owner;
      return otherFields;
    });
    formData.append("owners", JSON.stringify(ownersWithoutFiles));

    const fields: (keyof CompanyOwner)[] = [
      "company_owner_photo",
      "company_owner_nid_file",
      "company_owner_biodata",
      "company_owner_passport",
      "company_owner_signature",
    ];

    companyOwners.forEach((owner: CompanyOwner, index: number) => {
      fields.forEach((field) => {
        const file = owner[field];
        if (file instanceof File) {
          formData.append(`${field}_${index}`, file);
        }
      });
    });
    console.table(Object.fromEntries(formData));
    // return;
    mutation.mutate(formData);
  };

  const [ownership, setOwnership] = useState("");

  return (
    <Box component="form" onSubmit={handleSubmit} p={2}>
      <Box>
        <FormControl sx={{ width: { xs: "100%", md: 300 } }}>
          <InputLabel id="demo-simple-select-label">
            Select ownership status
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="ownership_status"
            label="Select ownership status"
            sx={{ borderRadius: "10px" }}
            value={ownership}
            required
            onChange={(e: any) => {
              setOwnership(e.target.value);
            }}
          >
            <MenuItem value={"Proprietorship"}>Proprietorship</MenuItem>
            <MenuItem value={"Partnership"}>Partnership</MenuItem>
            <MenuItem value={"Incorporator"}>Incorporator</MenuItem>
          </Select>
        </FormControl>

        {/* {error.ownershipStatus && (
          <FormHelperText error>Ownership status is required</FormHelperText>
        )} */}
      </Box>{" "}
      <>
        {companyOwners.map((inputField: any, index: number) => {
          return (
            <Box>
              {ownership && ownership !== "Proprietorship" && (
                <>
                  {" "}
                  {!user.is_applied && (
                    <Box
                      sx={{
                        textAlign: "end",
                        marginBottom: "-45px",
                        marginTop: "15px",
                      }}
                    >
                      {index > 0 && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() => handleRemoveFields(index)}
                        >
                          Remove
                        </Button>
                      )}

                      {index === 0 && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleAddFields()}
                        >
                          Add
                        </Button>
                      )}
                    </Box>
                  )}
                </>
              )}
              <Grid key={index} container spacing={1} columns={12}>
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerInputField
                    label="Name"
                    required
                    name="company_owner_name"
                    placeholder="Enter owner name"
                    value={inputField?.company_owner_name}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_name",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerInputField
                    label="Email address"
                    required
                    type="email"
                    name="company_owner_email"
                    placeholder="Enter owner email address"
                    value={inputField?.company_owner_email}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_email",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerInputField
                    label="Full address"
                    required
                    name="company_owner_address"
                    placeholder="Enter owner full address"
                    value={inputField?.company_owner_address}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_address",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerFileInput
                    label="Upload photo ( max size 5mb )"
                    required
                    name="company_owner_photo"
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_photo",
                        e.target.files[0]
                      )
                    }
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerInputField
                    label="Designation"
                    required
                    name="company_owner_designation"
                    placeholder="Enter owner designation"
                    value={inputField?.company_owner_designation}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_designation",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerInputField
                    label="Phone number"
                    required
                    type="number"
                    name="company_owner_phone"
                    placeholder="Enter owner phone number"
                    value={inputField?.company_owner_phone}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_phone",
                        e.target.value
                      )
                    }
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerFileInput
                    label="Upload Biodata ( max size 5mb )"
                    name="company_owner_biodata"
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_biodata",
                        e.target.files[0]
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerFileInput
                    label="Upload applicant seal & signature ( max size 5mb )"
                    required
                    name="company_owner_signature"
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_signature",
                        e.target.files[0]
                      )
                    }
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerInputField
                    label="NID number"
                    required
                    type="number"
                    name="company_owner_nid"
                    placeholder="Enter owner NID number"
                    value={inputField?.company_owner_nid}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_nid",
                        e.target.value
                      )
                    }
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerFileInput
                    label="Upload NID (Both Side) ( max size 5mb )"
                    required
                    name="company_owner_nid_file"
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_nid_file",
                        e.target.files[0]
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <OwnerFileInput
                    label="Upload passport ( max size 5mb )"
                    name="company_owner_passport"
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "company_owner_passport",
                        e.target.files[0]
                      )
                    }
                  />
                </Grid>
                {ownership && ownership !== "Proprietorship" && (
                  <Grid item xs={12} md={6} lg={4}>
                    <Box mt={4}>
                      {" "}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Represent organization
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="company_representative"
                          value={inputField?.company_representative}
                          label="Represent organization"
                          onChange={(e: any) =>
                            handleChangeInput(
                              index,
                              "company_representative",
                              Boolean(e.target.value)
                            )
                          }
                        >
                          <MenuItem value={"true"}>Yes</MenuItem>
                          <MenuItem value={"false"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Divider sx={{ my: 2 }} />
            </Box>
          );
        })}
      </>
      =
      {!user.is_applied && (
        <Box sx={{ mt: 5, textAlign: "end", mb: 1 }}>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? "Loading..." : "Save"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const OwnerInputField = ({
  label,
  required = false,
  placeholder,
  type = "text",
  defaultValue = "",
  onChange,
  value,
}: any) => (
  <Box sx={{ mt: 2 }}>
    <label>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </label>
    <TextField
      variant="outlined"
      placeholder={placeholder}
      type={type}
      value={value}
      required={required}
      defaultValue={defaultValue}
      fullWidth
      onChange={onChange}
    />
  </Box>
);

const OwnerFileInput = ({ label, required = false, onChange, name }: any) => (
  <Box sx={{ mt: 2 }}>
    <label>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </label>
    <TextField
      name={name}
      variant="outlined"
      type="file"
      required={required}
      fullWidth
      onChange={onChange}
    />
  </Box>
);
